$green: #285c4d;
$gray: #333;
$small-width: 520px;
$medium-width: 1024px;

html,
body,
#map {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: sans-serif;
}

#menu {
  padding-top: 1.5em;
  font-size: 0.9em;
  h1 {
    background-color: $green !important;
    img {
      float: right;
      max-width: 65%;
      height: auto;
    }
  }
}

#hvc-menu {
  p {
    line-height: 1.8em;
    padding-left: 5px;
  }
  b,
  a {
    color: $green;
  }
}

.title-bg {
  padding: 18px;
  border: none;
  text-align: left;
  outline: none;
  color: #fff;
}

.accordion {
  font-weight: bold;
  background-color: rgba($green, 0.6);
  cursor: pointer;
  width: 100%;
  border: none;
  font-size: 15px;
  transition: all 0.4s ease;
  &:hover,
  &.is-active {
    background-color: $green !important;
  }
}

.table-container {
  background-color: #fff;
  overflow: auto;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    &:nth-child(even) {
      background-color: rgba($gray, 0.1);
    }
    &:hover {
      background-color: rgba($gray, 0.2);
    }
  }

  th {
    border: none;
    padding: 12px 5px 12px 5px;
    text-align: left;
    background-color: rgba($green, 0.5);
    color: #fff;
  }

  td {
    border: none;
    padding: 8px;
    i {
      color: $green;
    }
  }
}

.ol-overlay {
  &.menu {
    z-index: 12;
    width: 30%;
    background: #fff;
    color: $gray;
    box-shadow: 0 0 5px #000;
    padding: 0.5em;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    overflow-y: scroll;
    @media only screen and (max-width: $medium-width) {
      width: 45%;
    }
    @media only screen and (max-width: $small-width) {
      width: 65%;
    }
  }
  .ol-closebox::before {
    color: $green;
    content: '\2715';
  }
  &.menu .ol-closebox {
    right: 1em;
    top: 0.5em;
  }
}

.ol-tooltip {
  position: relative;
  background: rgba($green, 0.8);
  border-radius: 4px;
  color: #fff;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}

.ol-zoom {
  top: 2.5em;
}

.upload-bar {
  top: 7em !important;
  left: 0.5em;
}

.export-map-button {
  top: 8em;
  left: 0.5em;
}

@media only screen and (max-width: $medium-width) {
  .ol-zoom {
    top: 3em;
  }
  .upload-bar {
    top: 9em !important;
  }
  .export-map-button  {
    top: 10em;
  }
}



.search-location-button {
  top: 0.5em;
  left: 3em;
}
.ol-control {
  &.menu {
    top: 0.5em;
    left: 0.5em;
    i {
      color: #fff;
    }
  }
  button {
    background-color: rgba($green, 0.7);
    &:hover,
    &:focus {
      background-color: rgba($green, 0.9);
    }
  }
}

.ol-attribution a {
  color: $green;
}

.ol-search.searching:before {
  display: none;
}

.ol-scale-line {
  background: rgba($green, 0.7);
}

.ol-control.ol-bar .ol-control.ol-text-button > div {
  color: rgba($green, 0.5);
}

.ol-control.ol-bar .ol-control.ol-text-button > div:hover {
  color: rgba($green, 0.7);
}

.ol-control.ol-bar .ol-control.ol-option-bar {
  box-shadow: 0 0 0 1px rgba($green, 0.5), 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.ol-control.ol-bar.ol-left .ol-option-bar:before {
  border-color: transparent rgba($green, 0.5) transparent transparent;
}

.ol-control.ol-bar .ol-control.ol-text-button {
  border-left: 1px solid rgba($green, 0.8);
}

.ol-layerswitcher {
  [type='checkbox'] {
    + label:before,
    &:checked + label:after {
      border-color: $green;
    }
  }
  .layerswitcher-opacity {
    border-color: $green;
    background: $green;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba($green, 0)),
      to(rgba($green, 0.6))
    );
    background: linear-gradient(to right, rgba($green, 0), rgba($green, 0.6));
    .layerswitcher-opacity-cursor,
    &:before {
      background: rgba($green, 0.5);
    }
  }
}

.menu {
  overflow: auto;
}

.panel {
  width: 270px;
}

.layerswitcher-opacity {
  max-width: 100%;
}

.ol-layerswitcher .panel .li-content {
  label {
    max-width: 100%;
  }
  .layerswitcher-opacity {
    width: 240px;
  }
}

.ol-title,
.ol-button {
  h2 {
    color: $green;
  }
}

.ol-buttons {
  input {
    color: $green !important;
  }
}

.ui-autocomplete,
.ui-menu {
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ui-menu-item-wrapper:hover {
  background: rgba($green, 0.6);
  border-color: rgba($green, 0.6);
}

#search-location {
  width: 285px;
}

#loader-container {
  height: 60px;
  display: flex;
  padding-top: 12px;
}

#loader-text {
  padding: 10px 10px 0 0;
}

#loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid $green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
